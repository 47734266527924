import * as React from "react";
import { PageProps, graphql, Link } from "gatsby";
import Navbar from "../components/navbar";
import {
  Jumbotron,
  JumbotronContent,
  JumbotronH2,
  JumbotronH1,
} from "../components/jumbotron";
import { Main } from "../components/main";
import { GtagInit } from "../components/conversion";
import _ from "lodash";

const Blog = (props: PageProps) => {
  const posts = (props.data as any).allMarkdownRemark.edges
    .map(e => e.node)
    .filter(
      n =>
        n.frontmatter &&
        n.frontmatter.publish == true &&
        n.frontmatter.template == "blog"
    );
  return (
    <>
      <Navbar />
      <div className="container">
        <Jumbotron>
          <JumbotronContent>
            <JumbotronH1>
              Assurance Décennale
              <JumbotronH2>Le Blog</JumbotronH2>
            </JumbotronH1>
          </JumbotronContent>
        </Jumbotron>
        <Main>
          <h1>Derniers articles... le blog</h1>
          <div className="mb-4 mt-4">
            {_.sortBy(posts, "fileAbsolutePath")
              .reverse()
              .map((n, i) => (
                <div key={i} className="mb-3">
                  <a href={n.frontmatter.path} className="h3 mt-2 mb-2">
                    {n.frontmatter.title}
                  </a>
                  <div>{n.frontmatter.description || n.excerpt}</div>
                </div>
              ))}
          </div>
        </Main>
      </div>
      <GtagInit />
    </>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          fileAbsolutePath
          excerpt(format: PLAIN)
          frontmatter {
            path
            title
            category
            template
            description
            publish
            banner {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  src
                }
              }
            }
          }
          tableOfContents
        }
      }
    }
  }
`;
